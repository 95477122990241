import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import BootstrapTable from "react-bootstrap-table-next";
import { PaperAirplaneIcon } from "@primer/octicons-react";

function UserDetails(user) {
  const [applications, setApplications] = useState(user.user.applications);
  const [userInfo, setUserInfo] = useState(user.user);

  useEffect(() => {
    setApplications(user.user.applications);
    setUserInfo(user.user);
  }, [user]);

  const [appTableCols, setAppTableCols] = useState([
    { dataField: "applicationId", text: "ApplicationId", hidden: true },
    { dataField: "grantTypeId", text: "typeId", hidden: true },
    { dataField: "userId", text: "UserId", hidden: true },
    { dataField: "grantType.type", text: "Grant Type" },
    {
      dataField: "applicantName",
      text: "Applicant Name",
      sort: true,
    },
    {
      dataField: "organization",
      text: "Organization",
      sort: true,
    },
    {
      dataField: "submitDate",
      text: "Submit Date",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.submitDate) {
          let dt = new Date(row.submitDate);
          let options = {
            timeZone: "America/New_York",
          };
          return dt.toLocaleString("en-US", options);
        }
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ]);
  const [selectedApplication, setSelectedApplication] = useState();
  const [selectedRow, setSelectedRow] = useState();

  const gotoApplication = (app) => {
    //open application
    window.open(`/submissions/${app.applicationId}`, "_blank");
  };

  const onEditUserClick = (e) => {
    //pop up edit user model
  };

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "LightBlue",
    headerColumnStyle: { width: "76px" },
    selected: selectedRow,

    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedApplication(row);
      setSelectedRow([row.applicationId]);
    },

    selectionRenderer: ({ mode, checked, disabled }) => {
      return (
        <div className="container p-0 m-0 d-flex justify-content-center">
          <div className="btn-group">
            {checked === true && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    gotoApplication(selectedApplication);
                  }}
                >
                  <PaperAirplaneIcon />
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    },
  };

  return (
    <React.Fragment>
      <Card body>
        <Form>
          <Form.Group className="pr-5">
            <div className="row pb-3 pr-2 pl-2">
              <h6 className="m-0 pt-1">User Information</h6>
              {/*<Button
                variant="primary"
                size="sm"
                className="ml-3"
                onClick={onEditUserClick}
              >
                Edit User
              </Button>*/}
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">User Email</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={userInfo.user.email}
                />
              </div>
              <label className="col-md-2 text-right">User Roles</label>
              <div className="col-md-4">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={userInfo.role_str}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">Phone Number</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={
                    userInfo.user.phoneNumber === null
                      ? " "
                      : userInfo.user.phoneNumber
                  }
                />
              </div>
              <label className="col-md-2 text-right">Grant Types</label>
              <div className="col-md-4">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={userInfo.ugt_str}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <BootstrapTable
              keyField="applicationId"
              bootstrap4={true}
              data={applications}
              columns={appTableCols}
              striped={true}
              bordered={true}
              hover={true}
              condensed={true}
              defaultSorted={[{ dataField: "submitDate", order: "desc" }]}
              selectRow={selectRowProp}
              wrapperClasses="applicationTable"
              noDataIndication="No applications submitted"
            />
          </Form.Group>
        </Form>
      </Card>
    </React.Fragment>
  );
}

export default UserDetails;
