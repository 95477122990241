import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

function InitApplications() {
  let { id } = useParams();

  const redir = () => {
    return <Redirect to={"/submissions/" + id} />;
  };

  return redir();
}

export default InitApplications;
