import React, { Component } from 'react';

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.error) {
      return <h1> Something went wrong. </h1>
    }
    return this.props.children;
  }
}



export default ErrorBoundary;
