import {
  ApplicationPaths,
  QueryParameterNames,
} from "../api-authorization/ApiAuthorizationConstants";

const AuthErrorHelper = {
  reLogin(href) {
    var link = document.createElement("a");

    link.href = href;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(returnUrl)}`;
    //return <Redirect to={redirectUrl} />;

    return redirectUrl;
  },

  returnHome(href) {
    var link = document.createElement("a");

    link.href = href;
    const returnUrl = `${link.protocol}//${link.host}${"/applications"}${
      link.search
    }${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(returnUrl)}`;
    //return <Redirect to={redirectUrl} />;

    return redirectUrl;
  },
};

export default AuthErrorHelper;
