import React, { useState, useEffect } from "react";
import authService from "./api-authorization/AuthorizeService";
import UserApplications from "./UserApplications";
import jwt_decode from "jwt-decode";

function AdminApplications({ location }) {
  return <UserApplications location={location} type="all" />;
}

export default AdminApplications;
