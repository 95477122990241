import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

function PopoverAlert({ id, show, target, title, content }) {
  let newText = content.split(",").map((i) => {
    return <p key={i}>{i}</p>;
  });
  return (
    <Overlay target={target} show={show} placement="bottom">
      <Popover id={id}>
        <Popover.Title as="h3">{title}</Popover.Title>
        <Popover.Content className="pop-width">{newText}</Popover.Content>
      </Popover>
    </Overlay>
  );
}

export default PopoverAlert;
