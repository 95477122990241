import React, { useState, useEffect } from "react";
import { Component } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";

/*
function AuthorizeRoute(props) {
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    const sub = authService.subscribe(() => authenticationChanged());
    setSubscription(sub);

    populateAuthenticationState();

    return () => {
      authService.unsubscribe(subscription);
    };
  }, []);

  async function populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    setReady(true);
    setAuthenticated(authenticated);
  }

  async function authenticationChanged() {
    setReady(false);
    setAuthenticated(false);
    await populateAuthenticationState();
  }

  var link = document.createElement("a");
  link.href = props.path;

  //   const loc = useLocation();
  //   console.log(loc);
  //   link.href = loc.pathname;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURI(returnUrl)}`;
  if (!ready) {
    return <div></div>;
  } else {
    const { component: Component, ...rest } = props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (authenticated) {
            return <Component {...props} />;
          } else {
            return <Redirect to={redirectUrl} />;
          }
        }}
      />
    );
  }
}

export default AuthorizeRoute;
*/

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    //link.href = this.props.path;
    link.href = this.props.currentLocation.pathname;
    //link.href = useLocation();
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const { component: Component, ...rest } = this.props;
      return (
        <Route
          {...rest}
          render={(props) => {
            if (authenticated) {
              return <Component {...props} />;
            } else {
              return <Redirect to={redirectUrl} />;
            }
          }}
        />
      );
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
