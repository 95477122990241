import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function CopyLinkModal({ show, title, link, onClose }) {
  const closeModal = (e) => {
    onClose(e);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <h6 className="m-0">{title}</h6>
      </Modal.Header>
      <Modal.Body>
        <div className="modalWindow">
          <Form>
            <Form.Group>
              <Form.Label>
                Use the application link below to share the selected application
                with a grant administrator.
              </Form.Label>
              <Form.Control type="text" readOnly defaultValue={link} />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CopyLinkModal;
