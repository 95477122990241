import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import BootstrapTable from "react-bootstrap-table-next";
import Alert from "react-bootstrap/Alert";
import { DesktopDownloadIcon } from "@primer/octicons-react";
import { useHistory } from "react-router-dom";
import authService from "./api-authorization/AuthorizeService";
import Config from "../config.json";
import AuthErrorHelper from "./shared/AuthErrorHelper";
import CopyLinkModal from "./modals/CopyLinkModal";
import ConfirmModal from "./modals/ConfirmModal";
import PopoverAlert from "./shared/PopoverAlert";
import jwt_decode from "jwt-decode";

function ApplicationDetails({
  location,
  selectedApplication,
  downloadZip,
  type,
  isAdmin,
  hideFileStatus,
  handleStatusChange,
}) { 
  const submitBtn = useRef(null); 
  
  const [btnHidden, setbtnHidden] = useState(false);
  useEffect(() => {    
    setbtnHidden(!isAdmin)    
  }, [ isAdmin ]);

  const [files, setFiles] = useState([]);
  const [fileStatus, setFileStatus] = useState([
    { id: "1", value: "" },
    { id: "2", value: "For Review" },
  ]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const closedErrorMessage = {
    show: false,
    title: "",
    content: "",
  };
  const [errorMessage, setErrorMessage] = useState(closedErrorMessage);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState("");

  const [filesTableCols, setFilesTableCols] = useState([
    { dataField: "applicationFileId", text: "ApplicationFileId", hidden: true },
    { dataField: "fileName", text: "File Name", sort: true },
    { dataField: "fileSize", text: "File Size" },
    {
      dataField: "reviewStatus",
      hidden: hideFileStatus,
      text: "Status",
      headerStyle: () => {
        return { width: "20%" };
      },
      formatExtraData: type === "all",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const opts = fileStatus.map((s) => {
          let selected = false;
          if (row["reviewStatus"] === s.value) {
            selected = true;
          }
          return (
            <option value={s.value} selected={selected}>
              {s.value}
            </option>
          );
        });
        return formatExtraData ? (
          <select
            className="form-select-control form-control-sm-table"
            onClick={(e) => {
              //e.defaultPrevented();
              e.stopPropagation();
            }}
            onChange={(e) => {
              onUpdateFileStatus(row["applicationFileId"], e.target.value);
            }}
          >
            {opts}
          </select>
        ) : (
          <React.Fragment>{row["reviewStatus"]}</React.Fragment>
        );
      },
    },
  ]);

  const [selectedAttachment, setSelectedAttachment] = useState();
  const [showCopyLinkModal, setShowCopyLinkModal] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const [appLink, setAppLink] = useState("");
  const history = useHistory();

  useEffect(() => {
    getFilesByAppId();
    //setIsAdminRole(isAdmin);
  }, [selectedApplication, isAdmin]);

  async function getFilesByAppId() {
    let id = selectedApplication.applicationId;
    try {
      const token = await authService.getAccessToken();
      const { data: files } = await axios.get(
        Config.apiUrl + "api/ApplicationFiles/" + id + "/" + type,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      if (files.length > 0) {
        setFiles(files);
      } else {
        setFiles([]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  }

  async function onUpdateFileStatus(fileId, status) {
    //console.log(fileId);
    //console.log(status);
    let file = {
      ApplicationFileId: fileId,
      ReviewStatus: status,
    };

    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.put(
        Config.apiUrl + "api/ApplicationFiles/" + fileId,
        file,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log("File Status Update Error");
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  }

  const handleSingleFileDownload = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log("selected file id " + selectedAttachment.applicationFileId);
    let id = selectedAttachment.applicationFileId;
    let file_name = selectedAttachment.fileName;
    //handle file download
    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.get(
        Config.apiUrl + "api/ApplicationFiles/download/fileId/" + id,
        {
          responseType: "blob",
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );

      //console.log(response);
      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const onCopyLinkClick = (e) => {
    const link =
      window.location.origin + "/init/" + selectedApplication.applicationId;
    setAppLink(link);
    setShowCopyLinkModal(true);
  };

  const onApplicationDelete = () => {
    setConfirmType("DeleteApp");
    setConfirmTitle("Delete Application");
    setShowConfirmModal(true);
  };

  const onApplicationSubmit = () => {
    if (files.length > 0) {
      setErrorMessage(closedErrorMessage);
      setConfirmType("Submit");
      setConfirmTitle("Submit Application");
      setShowConfirmModal(true);
    } else {
      setErrorMessage({
        show: true,
        title: "No Attachments Found",
        content:
          "Please edit to upload attachments before submitting your application.",
      });
      setTimeout(closeErrorMessage, 5000);
      return;
    }
  };
  const closeErrorMessage = () => {
    setErrorMessage(closedErrorMessage);
  };
  const onApplicationEdit = () => {
    //add confirm window for submitted application edit for admin
    let app = { ...selectedApplication };
    if (isAdmin && app.status === "Submitted") {
      setConfirmType("Edit");
      setConfirmTitle("Edit Application");
      setShowConfirmModal(true);
    } else {
      console.log(selectedApplication.applicationId);
      console.log(isAdmin);
      const url = "/editapplication";
      const redirObj = {
        pathname: url,
        state: {
          referrer: location,
          id: selectedApplication.applicationId,
          file: files,
          app: selectedApplication,
          isadmin: isAdmin,
        },
      };
      history.push(redirObj);
    }
  };

  const handleConfirm = async (type) => {    
    if (type === "Cancel") {
      history.goBack();
    } else if (type === "Submit") {
      await updateApplication();
    } else if (type == "DeleteApp") {
      await DeleteApplication();
    } else if (type === "Edit") {
      console.log(selectedApplication.applicationId);
      console.log(isAdmin);
      const url = "/editapplication";
      const redirObj = {
        pathname: url,
        state: {
          referrer: location,
          id: selectedApplication.applicationId,
          file: files,
          app: selectedApplication,
          isadmin: isAdmin,
        },
      };
      history.push(redirObj);
    }
    //reset confirm modal
    setConfirmTitle("");
    setShowConfirmModal(false);
  };

  const DeleteApplication = async () => {
    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.delete(
        Config.apiUrl + "api/Applications/" + selectedApplication.applicationId,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
      handleStatusChange(0);
    } catch (error) {
      console.log("Application Delete Method Error");
      console.log(error);
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const updateApplication = async () => {
    let app = { ...selectedApplication };
    app.status = "Submitted";

    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.post(
        Config.apiUrl +
          "api/Applications/SubmitApplication/" +
          app.applicationId,
        app,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
      handleStatusChange(app.applicationId);
    } catch (error) {
      console.log("Application Post Method Error");
      console.log(error);
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "LightBlue",
    headerColumnStyle: { width: "76px" },
    //selected: selectedRow,

    //Selection row
    selectionRenderer: ({ mode, checked, disabled }) => {
      return (
        <div className="container p-0 m-0 d-flex justify-content-center">
          <div className="btn-group">
            {checked === true && (
              <React.Fragment>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleSingleFileDownload}
                >
                  <DesktopDownloadIcon />
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    },

    //Selection header
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
      return <React.Fragment>Download</React.Fragment>;
    },

    //Selection callback
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedAttachment(row);
    },
  };

  

  return (
    <React.Fragment>
      {selectedApplication.status === "Incomplete" && (
        <Alert variant={"warning"}>
          NOTE: Incomplete applications must be submitted before the application
          deadline to be reviewed by MDOT MTA.
        </Alert>
      )}
      <Card body>
        <Form>
          <Form.Group className="pr-5">
            <div className="row pb-3 pr-2 pl-2 justify-content-between">
              <div>
                <h6 className="m-0 pt-1 d-sm-inline-flex">
                  Application Details
                </h6>
                <Button
                  variant="primary"
                  size="sm"
                  className="ml-3"
                  onClick={onCopyLinkClick}
                >
                  Copy Link
                </Button>
              </div>

              {(!btnHidden || selectedApplication.status === "Incomplete") && (
                <div>
                <Button
                  className="ml-3"
                  size="sm"
                  variant="primary"
                  onClick={onApplicationEdit}
                >
                  Edit
                </Button>
                <Button
                  className="ml-3"
                  size="sm"
                  variant="danger"
                  onClick={onApplicationDelete}
                >
                  Delete
                </Button>
                </div>
              )}              

              { selectedApplication.status === "Incomplete" && (
                <div>                  
                  <Button
                    className="ml-3"
                    size="sm"
                    variant="success"
                    onClick={onApplicationSubmit}
                    ref={submitBtn}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>

            <div className="form-group row">
              <label className="col-md-2 text-right">Applicant Name</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.applicantName}
                />
              </div>
              <label className="col-md-2 text-right">Applicant Type</label>
              <div className="col-md-4">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.grantType.type}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">Organization</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.organization}
                />
              </div>
              <label className="col-md-2 text-right">Applicant Title</label>
              <div className="col-md-4">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.applicantTitle}
                  //onChange={handleTextChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">Contact Email</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.email}
                />
              </div>
              <label className="col-md-2 text-right">Contact Phone</label>
              <div className="col-md-4">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.phone}
                  //onChange={handleTextChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">Description</label>
              <div className="col-md-4">
                <textarea
                  readOnly={true}
                  className="textarea-input"
                  value={selectedApplication.description}
                  //onChange={handleTextChange}
                />
              </div>
              <label className="col-md-2 text-right">Additional Notes</label>
              <div className="col-md-4">
                <textarea
                  readOnly={true}
                  className="textarea-input"
                  value={selectedApplication.notes}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">Address</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.address}
                  //onChange={handleTextChange}
                />
              </div>
              <label className="col-md-2 text-right">City</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.city}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2 text-right">Address 2</label>
              <div className="col-md-4">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.address2}
                />
              </div>
              <label className="col-md-2 text-right">State</label>
              <div className="col-md-2">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.state}
                />
              </div>
              <label className="col-md-1 text-right">Zip</label>
              <div className="col-md-1">
                <input
                  readOnly={true}
                  className="form-control form-control-sm"
                  value={selectedApplication.zip}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Button
              variant="primary"
              onClick={() => {
                downloadZip(selectedApplication);
              }}
              style={{ marginLeft: "auto" }}
              size="sm"
            >
              {" "}
              Download Attachments
            </Button>
          </Form.Group>
          <Form.Group>
            <BootstrapTable
              keyField="applicationFileId"
              bootstrap4={true}
              data={files}
              columns={filesTableCols}
              striped={true}
              bordered={true}
              hover={true}
              condensed={true}
              selectRow={selectRowProp}
              wrapperClasses="attachmentTable"
              noDataIndication="No files uploaded"
            />
          </Form.Group>
        </Form>
      </Card>

      <CopyLinkModal
        show={showCopyLinkModal}
        title="Copy Application Link"
        link={appLink}
        onClose={() => setShowCopyLinkModal(false)}
      />

      <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        type={confirmType}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
      />

      <PopoverAlert
        id="errorMessage"
        show={errorMessage.show}
        target={submitBtn}
        title={errorMessage.title}
        content={errorMessage.content}
      />
    </React.Fragment>
  );
}

export default ApplicationDetails;
