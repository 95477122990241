import React, { Component } from "react";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h3>Welcome to the MDOT MTA Grant Application Portal</h3>
        <p>
          Using this portal, you will be able to submit grant applications to
          MDOT MTA for review.
        </p>

        <p>To get started:</p>
        <ul>
          <li>Register for a new account using your email address.</li>
          <li>Login to the portal using your new account information.</li>
          <li>
            Create new grant applications, upload relevant documents, and submit
            the applications for review.
          </li>
        </ul>
        <p>
          If you have any questions regarding this process, please contact
          nhuggins@mdot.maryland.gov for more information.
        </p>
      </div>
    );
  }
}
