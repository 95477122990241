import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Config from "../../config.json";
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
import AuthErrorHelper from "../shared/AuthErrorHelper";
import { useHistory } from "react-router-dom";

function EditUserModal({
  show,
  onClose,
  onSave,
  user,
  roles,
  grantTypes,
  isReviewer,
  userGrantTypes,
  userId,
}) {
  const history = useHistory();
  const [userRole, setUserRole] = useState([]);
  const [types, setTypes] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user);

  useEffect(() => {
    setTypes(userGrantTypes);
    setHidden(!isReviewer);
    setSelectedUser(user);
  }, [userGrantTypes, isReviewer, user]);

  const rolesList = roles.map((role) => {
    let selected = false;
    if (user) {
      selected = role.name === user.role_str;
      if (role.name === "No Role" && user.role_str === "") {
        selected = true;
      }
    }
    return (
      <option value={role.name} selected={selected} key={role.name}>
        {role.name}
      </option>
    );
  });

  const checkValue = (type) => {
    let isChecked = false;
    if (user) {
      if (user.ugt_str != null) {
        isChecked = user.ugt_str.includes(type.name);
      } else {
        isChecked = false;
      }
    } else {
      isChecked = false;
    }
    return isChecked;
  };

  const onSaveUser = async (e) => {
    types.filter((type, i) => types.indexOf(type) === i);
    userRole.filter((role, i) => userRole.indexOf(role) === i);

    if (userRole.length == 0) {
      selectedUser.roles = selectedUser.roles;
    } else {
      selectedUser.roles = userRole;
    }

    selectedUser.userGrantTypes = types;
    //save
    try {
      const token = await authService.getAccessToken();
      const { data: result } = await axios.post(
        Config.apiUrl + "api/UserManage/EditUserInRole/" + selectedUser.user.id,
        //userRole, types,
        selectedUser,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
      //console.log(result);
      onSave(result);
    } catch (error) {
      console.log("Edit User Roles Error");
      if (error.response.status === 401) {
        redirectLogin();
      }
    }
  };

  const redirectLogin = () => {
    let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
    history.push(redirectUrl);
  };

  const closeModal = (e) => {
    onClose();
  };

  const handleSelectChange = (e) => {
    //handle select change
    setUserRole([e.target.value]);

    //hide/show grant type checkboxes
    if (e.target.value != "Reviewer") {
      setHidden(true);
    } else {
      setHidden(false);
    }
  };

  const handleClick = (e, i) => {
    if (e.target.checked) {
      setTypes([
        ...types,
        {
          UserGrantTypeId: 0,
          UserId: userId,
          GrantTypeId: Number(e.target.id),
        },
      ]);
    } else {
      setTypes(
        types.filter((type) => type.grantTypeId !== Number(e.target.id))
      );
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <h6 className="m-0">Edit User Roles</h6>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Assign Role</label>
            <select
              className="col-sm-6 form-control form-control-sm"
              onChange={handleSelectChange}
            >
              {rolesList}
            </select>
          </div>
          {!hidden ? (
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Assign Grant Types
              </label>
              {grantTypes.map((granttype, i) => (
                <div
                  key={granttype.id}
                  className="form-check form-check-inline"
                >
                  <input
                    className="form-check-input"
                    id={granttype.id}
                    type="checkbox"
                    value={granttype.name}
                    defaultChecked={checkValue(granttype)}
                    onClick={handleClick}
                    name="sameName"
                  />
                  <label className="form-check-label">{granttype.name}</label>
                </div>
              ))}
            </div>
          ) : (
            <div />
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary"
          size="sm"
          onClick={onSaveUser}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditUserModal;
