import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Config from "./config.json";
import authService from "./components/api-authorization/AuthorizeService";
import AuthErrorHelper from "./components/shared/AuthErrorHelper";

const defaultDomainContext = {
  appTypes: [],
  userRoles: [],
};

export const DomainContext = React.createContext(defaultDomainContext);

export const DomainProvider = ({ children }) => {
  const history = useHistory();
  const [appTypes, setAppTypes] = useState([]);
  const [reviewerAppTypes, setReviewerAppTypes] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  async function getReviewerApplicationTypes() {
    //TODO: api call get all types
    try {
      const token = await authService.getAccessToken();
      const { data: appTypes } = await axios.get(
        Config.apiUrl + "api/Applications/ReviewerGrantTypes",
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      let types = [];
      for (const t of appTypes) {
        types.push({
          id: t.grantTypeId,
          name: t.type,
          submitStatus: t.submitStatus,
        });
      }
      return types;
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  }

  async function getApplicationTypes() {
    //TODO: api call get all types
    try {
      const token = await authService.getAccessToken();
      const { data: appTypes } = await axios.get(
        Config.apiUrl + "api/Applications/GrantTypes",
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      let types = [];
      for (const t of appTypes) {
        types.push({
          id: t.grantTypeId,
          name: t.type,
          submitStatus: t.submitStatus,
        });
      }
      return types;
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  }

  async function getUserRoles() {
    try {
      const token = await authService.getAccessToken();
      const { data: userRoles } = await axios.get(
        Config.apiUrl + "api/UserManage/AllRoles",
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      let roles = [];
      for (const r of userRoles) {
        roles.push({ id: r.id, name: r.name });
      }
      return roles;
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  }

  async function refreshApplicationTypes() {
    setAppTypes(await getApplicationTypes());
    setReviewerAppTypes(await getReviewerApplicationTypes());
  }

  useEffect(() => {
    async function fetchData() {
      // await here
      refreshApplicationTypes();
      setUserRoles(await getUserRoles());
    }
    fetchData();
  }, []);

  return (
    <DomainContext.Provider
      value={{ appTypes, reviewerAppTypes, userRoles, refreshApplicationTypes }}
    >
      {children}
    </DomainContext.Provider>
  );
};
