import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect } from "react";
import Config from "../../config.json";
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
import AuthErrorHelper from "../shared/AuthErrorHelper";
import { useHistory } from "react-router-dom";

function EditGrantTypeModal({ show, onClose, grantTypes, onSave }) {
  const history = useHistory();
  const options = ["Enabled", "Disabled"];

  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(grantTypes);
  }, [grantTypes]);

  const handleOptionChange = (e) => {
    setTypes([
      ...types.filter((o) => o.id !== Number(e.target.id)),
      {
        id: Number(e.target.id),
        name: e.target.name,
        submitStatus: e.target.value,
      },
    ]);
    console.log(types);
  };

  const closeModal = (e) => {
    onClose(e);
  };

  const onSaveGrantType = async (e) => {
    let newArr = [];
    for (const t of types) {
      newArr.push({
        GrantTypeId: t.id,
        Type: t.name,
        SubmitStatus: t.submitStatus,
      });
    }

    try {
      const token = await authService.getAccessToken();
      const { data: result } = await axios.post(
        Config.apiUrl + "api/Applications/EditGrantType/-1",
        newArr,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
      onSave(result);
    } catch (error) {
      console.log("Edit Grant Type Error");
      if (error.response.status === 401) {
        //redirectLogin();
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <h6 className="m-0">Manage Grant Types</h6>
      </Modal.Header>
      <Modal.Body>
        <p>
          Enable/disable the creation of new applications for each grant type
          below:
        </p>
        <Form>
          {grantTypes.map((granttype, i) => (
            <div key={granttype.id} className="form-group row">
              <label className="col-sm-4 col-form-label">
                {granttype.name}
              </label>

              {options.map((option, j) => (
                <div key={option} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id={granttype.id}
                    value={option}
                    name={granttype.name}
                    defaultChecked={granttype.submitStatus === option}
                    onChange={handleOptionChange}
                  />
                  <label className="form-check-label">{option}</label>
                </div>
              ))}
            </div>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary"
          size="sm"
          onClick={onSaveGrantType}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditGrantTypeModal;
