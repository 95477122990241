import React, { Component } from "react";
import { Route, useLocation } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import UserApplications from "./components/UserApplications";
import AdminApplications from "./components/AdminApplications";
import InitApplications from "./components/InitApplications";
import CreateApplication from "./components/CreateApplication";
import UserList from "./components/UserList";
import "./custom.css";

export default function App() {
  return (
    <Layout>
      <Route exact path="/" component={Home} />
      <AuthorizeRoute
        path="/applications"
        currentLocation={useLocation()}
        component={UserApplications}
      />
      <AuthorizeRoute
        path="/newapplication"
        currentLocation={useLocation()}
        component={CreateApplication}
      />
      <AuthorizeRoute
        path="/editapplication"
        currentLocation={useLocation()}
        component={CreateApplication}
      />
      {/* <AuthorizeRoute path="/submissions" component={AdminApplications} /> */}
      <AuthorizeRoute
        path="/submissions/:id"
        currentLocation={useLocation()}
        component={AdminApplications}
      />
      <AuthorizeRoute
        path="/init/:id"
        currentLocation={useLocation()}
        component={InitApplications}
      />
      <AuthorizeRoute
        path="/users/"
        currentLocation={useLocation()}
        component={UserList}
      />
      <Route
        path={ApplicationPaths.ApiAuthorizationPrefix}
        component={ApiAuthorizationRoutes}
      />
    </Layout>
  );
}
