import React, { useState, useEffect, useRef, useContext } from "react";
import authService from "./api-authorization/AuthorizeService";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import ConfirmModal from "./modals/ConfirmModal";
import UploadAttachmentModal from "./modals/UploadAttachmentModal";
import { TrashIcon } from "@primer/octicons-react";
import Config from "../config.json";
import AuthErrorHelper from "./shared/AuthErrorHelper";
import { DomainContext } from "../Context";
import PopoverAlert from "./shared/PopoverAlert";
import { jwt_decode } from "jwt-decode";

function CreateApplication({ location, type = "user" }) {
  const [step, setStep] = useState("info");
  const history = useHistory();
  const [prevLocation, setPrevLocation] = useState(location);

  const { appTypes } = useContext(DomainContext);
  const { userRoles } = useContext(DomainContext);

  const [appId, setAppId] = useState(0);
  const [appName, setAppName] = useState("");
  const [appType, setAppType] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [appTitle, setAppTitle] = useState("");
  const [organization, setOrganization] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");

  const [submitDate, setSubmitDate] = useState("");
  const [status, setStatus] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const [btnSubmit, setBtnSubmit] = useState("Create");
  const typesList = appTypes.map((t) => {
    return (
      <React.Fragment key={t.id}>
        {(t.submitStatus === "Enabled" || isAdmin === true) && (
          <option key={t.id} value={t.id} selected={t.id === appType}>
            {t.name}
          </option>
        )}
      </React.Fragment>
    );
  });

  //confirm modal
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState("");

  //form validation
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (prevLocation.state) {
      setAppId(prevLocation.state.id);
      if (prevLocation.state.app) {
        setBtnSubmit("Save");

        let appInfo = prevLocation.state.app;
        setAppName(appInfo.applicantName);
        setAppType(appInfo.grantType.grantTypeId);
        setContactEmail(appInfo.email);
        setContactPhone(appInfo.phone);
        setAppTitle(appInfo.applicantTitle);
        setOrganization(appInfo.organization);
        setAddress(appInfo.address);
        setAddress2(appInfo.address2);
        setCity(appInfo.city);
        setState(appInfo.state);
        setZipcode(appInfo.zip);
        setDescription(appInfo.description);
        setNotes(appInfo.notes);
        if (prevLocation.state.isadmin) {
          setStatus(appInfo.status);
          setSubmitDate(appInfo.submitDate);
          setIsAdmin(true);
        }
      }
      if (prevLocation.state.file) {
        let files = prevLocation.state.file;
        setAttachments(files);
      }
    }
  }, []);

  const handleSelectChange = (event) => {
    //console.log(event.target.value);
    setAppType(event.target.value);
  };

  const handleTextChange = (event) => {
    event.stopPropagation();
    switch (event.target.id) {
      case "applicantName":
        setAppName(event.target.value);
        break;
      case "contactEmail":
        setContactEmail(event.target.value);
        break;
      case "contactPhone":
        setContactPhone(event.target.value);
        break;
      case "applicantTitle":
        setAppTitle(event.target.value);
        break;
      case "organization":
        setOrganization(event.target.value);
        break;
      case "address":
        setAddress(event.target.value);
        break;
      case "address2":
        setAddress2(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "state":
        setState(event.target.value);
        break;
      case "zipcode":
        setZipcode(event.target.value);
        break;
      case "description":
        setDescription(event.target.value);
        break;
      case "notes":
        setNotes(event.target.value);
        break;
    }
  };

  const handleNextStep = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setStep("upload");
    }
    setValidated(true);
  };

  const onApplicationCancel = () => {
    setConfirmType("Cancel");
    if (btnSubmit === "Save") {
      setConfirmTitle("Cancel Application Changes");
    } else {
      setConfirmTitle("Cancel New Application");
    }

    setShowConfirmModal(true);
  };

  /*************** Add Attachment Step ****************** */

  const [selectedAttachment, setSelectedAttachment] = useState();
  const [attachmentTableCols, setAttachmentTableCols] = useState([
    { dataField: "applicationId", text: "ApplicationId", hidden: true },
    { dataField: "applicationFileId", text: "FileId", hidden: true },
    { dataField: "fileName", text: "File Name", sort: true },
    //{ dataField: "fileDescription", text: "File Description", sort: true },
    { dataField: "fileSize", text: "File Size (Bytes)", sort: true },
  ]);
  const [attachments, setAttachments] = useState([]);
  const [appInfo, setAppInfo] = useState();

  const [showUploadAttachment, setShowUploadAttachment] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const closedErrorMessage = {
    show: false,
    title: "",
    content: "",
  };
  const [errorMessage, setErrorMessage] = useState(closedErrorMessage);
  const createBtn = useRef(null);

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "LightBlue",
    headerColumnStyle: { width: "76px" },
    //selected: selectedRow,

    //Selection row
    selectionRenderer: ({ mode, checked, disabled }) => {
      return (
        <div className="container p-0 m-0 d-flex justify-content-center">
          <div className="btn-group">
            {checked === true && (
              <React.Fragment>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleAttachmentDelete}
                >
                  <TrashIcon />
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    },
    //Selection header
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
      return <React.Fragment>Actions</React.Fragment>;
    },

    //Selection callback
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedAttachment(row);
      //setSelectedRow([row.applicationId]);
    },
  };

  const handleAttachmentDelete = (e) => {
    e.preventDefault();
    console.log("to delete" + selectedAttachment.applicationFileId);
    setConfirmType("Delete");
    setConfirmTitle("Delete Confirm");
    setShowConfirmModal(true);
  };
  const onUploadFile = () => setShowUploadAttachment(true);

  const onUploadAttachment = (files) => {
    // populate files info
    //setState([...myArray, 'new value']) //simple value
    setAttachments((attachments) => attachments.concat(files));
  };

  const onApplicationCreate = () => {
    if (isAdmin && status === "Submitted") {
      setConfirmType("AdminSave");
    } else {
      setConfirmType("Save");
    }
    //setConfirmType("Save");
    setConfirmTitle("Save Application");
    setShowConfirmModal(true);
    return;
  };

  const handleConfirm = async (type) => {
    const redirObj = {
      pathname: "/applications",
      id: appId,
    };
    if (type === "Cancel") {
      if (status === "Submitted") {
        history.push({
          pathname: "/submissions/0",
          //id: appId,
        });
      } else {
        history.push(redirObj);
      }
    } else if (type === "Save") {
      await updateApplication(type);

      history.push({
        pathname: "/applications",
        id: appId,
      });
    } else if (type === "AdminSave") {
      await updateApplication(type);
      history.push({
        pathname: "/submissions/0",
        //id: appId,
      });
    } else if (type === "Delete") {
      //Delete a single attachment
      // call api for delete
      const isFile = await deleteAttachment();
      if (isFile) {
        //update datagrid
        let newAttachments = attachments;
        newAttachments = newAttachments.filter(
          (att) => att.applicationFileId !== isFile.applicationFileId
        );
        setAttachments([...newAttachments]);
      }
      //reset confirm modal
      setConfirmTitle("");
      setConfirmTitle("");
      setShowConfirmModal(false);
    }
  };

  const updateApplication = async (type) => {
    let app = {
      ApplicationId: Number(appId),
      ApplicantName: appName,
      GrantTypeId: Number(appType),
      Phone: contactPhone,
      Email: contactEmail,
      ApplicantTitle: appTitle,
      Organization: organization,
      Address: address,
      Address2: address2,
      City: city,
      State: state,
      Zip: zipcode,
      Description: description,
      Notes: notes,
      Status: "Incomplete",
    };

    if (type === "AdminSave") {
      app.SubmitDate = submitDate;
      app.Status = status;
    }

    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.put(
        Config.apiUrl + "api/Applications/" + appId,
        app,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log("Application Put Method Error");
      console.log(error);
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const deleteAttachment = async () => {
    let id = selectedAttachment.applicationFileId;
    const token = await authService.getAccessToken();
    try {
      const { data: response } = await axios.delete(
        Config.apiUrl + "api/ApplicationFiles/" + id,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log("Delete Attachment Error");
      console.log(error);
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const handleAttachmentClose = () => {
    //reset confirm modal message
    setConfirmType("");
    setConfirmTitle("");
    setShowUploadAttachment(false);
  };

  return (
    <React.Fragment>
      {step === "info" && (
        <Form noValidate validated={validated} onSubmit={handleNextStep}>
          <Form.Group>
            <Form.Label>
              <h6>Step 1: Provide the required application details below:</h6>
            </Form.Label>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Applicant Information</h5>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="applicantName">Applicant Name</label>
                    <input
                      type="text"
                      required={true}
                      className="form-control form-control-sm"
                      id="applicantName"
                      value={appName}
                      onChange={handleTextChange}
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="type">Application Type</label>
                    <select
                      required={true}
                      className="form-control form-control-sm"
                      onChange={handleSelectChange}
                    >
                      <option value={appType}></option>
                      {typesList}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="organization">Organization</label>
                    <input
                      type="text"
                      required={true}
                      className="form-control form-control-sm"
                      id="organization"
                      value={organization}
                      onChange={handleTextChange}
                      placeholder="Organization"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="applicantTitle">Applicant Title</label>
                    <input
                      type="text"
                      required={true}
                      className="form-control form-control-sm"
                      id="applicantTitle"
                      value={appTitle}
                      onChange={handleTextChange}
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="contactEmail">Contact Email</label>
                    <input
                      type="text"
                      required={true}
                      className="form-control form-control-sm"
                      id="contactEmail"
                      value={contactEmail}
                      onChange={handleTextChange}
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="contactPhone">Contact Phone</label>
                    <input
                      type="text"
                      required={true}
                      className="form-control form-control-sm"
                      id="contactPhone"
                      value={contactPhone}
                      onChange={handleTextChange}
                      placeholder="Phone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2">
              <div className="card-body">
                <h5 className="card-title">Address Information</h5>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="address"
                    value={address}
                    onChange={handleTextChange}
                    placeholder="1234 Main St"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address2">Address 2</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="address2"
                    value={address2}
                    onChange={handleTextChange}
                    placeholder="Suite or Floor"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="city"
                      value={city}
                      onChange={handleTextChange}
                      placeholder="City"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="state"
                      value={state}
                      onChange={handleTextChange}
                      placeholder="State"
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <label htmlFor="zip">Zip</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="zipcode"
                      value={zipcode}
                      onChange={handleTextChange}
                      placeholder="Zip"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2">
              <div className="card-body">
                <h5 className="card-title">Additional Details</h5>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="textarea-input"
                      id="description"
                      value={description}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="notes">Additional Notes</label>
                    <textarea
                      className="textarea-input"
                      id="notes"
                      value={notes}
                      onChange={handleTextChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Button variant="secondary" onClick={onApplicationCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn-right"
              variant="primary"
              //onClick={() => setStep("upload")}
            >
              Next
            </Button>
          </Form.Group>
        </Form>
      )}
      {step === "upload" && (
        <React.Fragment>
          <Form>
            <Form.Group>
              <Form.Label>
                <h6>Step 2: Upload all relevant attachments below.</h6>
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <Button variant="primary" onClick={onUploadFile}>
                  Upload Attachment
                </Button>
              </Form.Label>

              <BootstrapTable
                keyField="applicationFileId"
                bootstrap4={true}
                data={attachments}
                columns={attachmentTableCols}
                striped={true}
                bordered={true}
                hover={true}
                selectRow={selectRowProp}
                wrapperClasses="attachmentTable"
                noDataIndication="No attachment uploaded"
              />
            </Form.Group>
            <Form.Group>
              <Button variant="secondary" onClick={() => setStep("info")}>
                Back
              </Button>
              <Button
                className="btn-right"
                variant="primary"
                onClick={onApplicationCreate}
                ref={createBtn}
              >
                {btnSubmit}
              </Button>
            </Form.Group>
          </Form>
          <UploadAttachmentModal
            show={showUploadAttachment}
            appId={appId}
            appInfo={appInfo}
            onClose={handleAttachmentClose}
            onUpload={onUploadAttachment}
          />
        </React.Fragment>
      )}
      <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        type={confirmType}
        //confirmButton={"Submit"}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
      />
      <PopoverAlert
        id="errorMessage"
        show={errorMessage.show}
        target={createBtn}
        title={errorMessage.title}
        content={errorMessage.content}
      />
    </React.Fragment>
  );
}

export default CreateApplication;
