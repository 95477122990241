import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import authService from "./api-authorization/AuthorizeService";
import ApplicationDetails from "./ApplicationDetails";
import { DesktopDownloadIcon } from "@primer/octicons-react";
import Config from "../config.json";
import AuthErrorHelper from "./shared/AuthErrorHelper";
import { Redirect, useParams, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { DomainContext } from "../Context";
import EditGrantTypeModal from "./modals/EditGrantTypeModal";

function UserApplications({ location, type = "user" }) {
  const { appTypes, reviewerAppTypes, refreshApplicationTypes } =
    useContext(DomainContext);
  const typesList = reviewerAppTypes.map((t) => {
    return (
      <option key={t.id} value={t.id} defaultValue={""}>
        {t.name}
      </option>
    );
  });

  const [statuses, setStatuses] = useState([
    { id: "1", value: "Submitted" },
    { id: "2", value: "Disqualified" },
  ]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [selectType, setSelectType] = useState(-1);
  const [applications, setApplications] = useState([]);

  const [applicationTableCols, setApplicationTableCols] = useState([
    { dataField: "applicationId", text: "ApplicationId", hidden: true },
    { dataField: "grantTypeId", text: "typeId", hidden: true },
    { dataField: "userId", text: "UserId", hidden: true },
    { dataField: "grantType.type", text: "Grant Type" },
    {
      dataField: "applicantName",
      text: "Applicant Name",
      sort: true,
    },
    {
      dataField: "organization",
      text: "Organization",
      sort: true,
    },
    {
      dataField: "submitDate",
      text: "Submit Date",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.submitDate) {
          let dt = new Date(row.submitDate);
          let options = {
            timeZone: "America/New_York",
          };
          return dt.toLocaleString("en-US", options);
        }
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatExtraData: false, //type === "all",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const opts = statuses.map((s) => {
          let selected = false;
          if (row["status"] === s.value) {
            selected = true;
          }
          return (
            <option key={s.value} value={s.value} selected={selected}>
              {s.value}
            </option>
          );
        });
        return formatExtraData ? (
          <select
            className="form-select-control form-control-sm-table"
            onClick={(e) => {
              //e.defaultPrevented();
              e.stopPropagation();
            }}
            onChange={(e) => {
              onUpdateApplicationStatus(row["applicationId"], e.target.value);
            }}
          >
            {opts}
          </select>
        ) : (
          <React.Fragment>{row["status"]}</React.Fragment>
        );
      },
    },
  ]);
  const [selectedApplication, setSelectedApplication] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [unauthorized, setUnauthorized] = useState(false);

  const [showEditGrantTypeModal, setShowEditGrantTypeModal] = useState(false);

  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    initApplications(selectType);
  }, [selectType, appTypes]);

  async function initApplications(typeId) {
    const token = await authService.getAccessToken();
    const decoded = jwt_decode(token);

    // Check permissions
    if (decoded.role == null && type === "all") {
      setUnauthorized(true);
    } else {
      if (type === "user") {
        refreshUserApplications();
      } else if (type === "all") {
        //set column for admin
        if (decoded.role && decoded.role.includes("Admin")) {
          setIsAdmin(true);
          let columns = [...applicationTableCols];
          let statusCol = columns.findIndex((c) => c.dataField === "status");
          columns[statusCol].formatExtraData = true;
          setApplicationTableCols(columns);
        }
        refreshAllApplications(typeId);
      }
    }
  }

  async function refreshUserApplications(appId) {
    try {
      const token = await authService.getAccessToken();
      const { data: apps } = await axios.get(
        Config.apiUrl + "api/Applications",
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );

      setApplications(apps);
      if (appId === 0 || appId === "undefined") {
        setSelectedRow([null]);
        setSelectedApplication(null);
      } else if (appId > 0) {
        const row = apps.find((r) => r.applicationId === appId);
        setSelectedRow([appId]);
        setSelectedApplication(row);
      } else if (location.id) {
        const row = apps.find((r) => r.applicationId === location.id);
        setSelectedRow([id]);
        setSelectedApplication(row);
      }
    } catch (error) {
      if (error.response.status === 401) {
        redirectLogin();
      }
    }
  }

  async function refreshAllApplications(typeId) {
    // TODO: Refresh all applications for submitted view for all applications
    // GET: api/Applications/AllApplications
    try {
      const token = await authService.getAccessToken();
      const { data: apps } = await axios.get(
        Config.apiUrl + "api/Applications/AllApplications/" + typeId,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      setApplications(apps);
      let defaultAppId = null;
      if (id && id > 0) {
        defaultAppId = parseInt(id);
        const row = apps.find((r) => r.applicationId === defaultAppId);
        setSelectedRow([defaultAppId]);
        setSelectedApplication(row);
      }
    } catch (error) {
      if (error.response.status === 401) {
        redirectLogin();
      }
    }
  }

  const onSaveGrantTypes = () => {
    setShowEditGrantTypeModal(false);
    //refreshApplicationTypes();
    refreshAllApplications(-1);
    refreshApplicationTypes();
    history.push({
      pathname: "/submissions/0",
      //id: appId,
    });
  };

  const onGrantTypeEdit = () => {
    setShowEditGrantTypeModal(true);
  };

  const handleSelectChange = (event) => {
    //console.log(event.target.id);
    setSelectType(event.target.value);
  };

  //This method is passed to Application Details child component
  //This method refreshes the component when status updates to submitted
  const handleStatusChange = (appId) => {
    console.log("Inside Handle", appId);
    refreshUserApplications(appId);
  };

  async function onUpdateApplicationStatus(appId, status) {
    // call api update application status
    // PUT: api/Applications/UpdateApplicationStatus/5
    let app = {
      ApplicationId: appId,
      Status: status,
    };

    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.put(
        Config.apiUrl + "api/Applications/UpdateApplicationStatus/" + appId,
        app,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log("Application Status Update Error");
      if (error.response.status === 401) {
        redirectLogin();
      }
    }
  }

  const redirectLogin = () => {
    let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
    history.push(redirectUrl);
  };

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "LightBlue",
    headerColumnStyle: { width: "76px" },
    selected: selectedRow,
    //Selection header

    onSelect: (row, isSelect, rowIndex, e) => {
      if (type === "all") {
        // Update selected route URL
        history.replace("/submissions/" + row.applicationId);
      }

      setSelectedApplication(row);
      setSelectedRow([row.applicationId]);
    },

    //Selection row
    selectionRenderer: ({ mode, checked, disabled }) => {
      return (
        <div className="container p-0 m-0 d-flex justify-content-center">
          <div className="btn-group">
            {checked === true && (
              <React.Fragment>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    downloadZip(selectedApplication);
                  }}
                >
                  <DesktopDownloadIcon />
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    },
  };

  const downloadZip = async (selApp) => {
    console.log("application id " + selApp.applicationId);

    let id = selApp.applicationId;
    //let date = new Date().toLocaleDateString();
    console.log(selApp);
    console.log(selApp.submitDate);

    let zipName = "";

    if (selApp.submitDate) {
      zipName =
        selApp.applicantName +
        "_" +
        selApp.organization +
        "_" +
        selApp.submitDate.substr(0, 10);
    } else {
      zipName = selApp.applicantName + "_" + selApp.organization;
    }
    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.get(
        Config.apiUrl +
          "api/ApplicationFiles/download/applicationId/" +
          id +
          "/" +
          type,
        {
          responseType: "blob",
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      //download attachment zip
      console.log(response);
      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", zipName);

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const createApplication = async (event) => {
    try {
      const token = await authService.getAccessToken();
      //const userId = userName;
      let app = {
        Status: "New",
      };

      const { data: newApp } = await axios.post(
        Config.apiUrl + "api/Applications",
        app,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );

      const url = "/newapplication";
      const redirObj = {
        pathname: url,
        state: { referrer: location, id: newApp.applicationId },
      };

      history.push(redirObj);
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  const exportApplication = async (event) => {
    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.get(
        Config.apiUrl + "api/Applications/ExportAsExcel",
        {
          responseType: "blob",
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      let date = new Date();

      let file_name =
        "Export_Application_" +
        date.getFullYear() +
        "_" +
        (date.getMonth() + 1) +
        "_" +
        date.getDate();

      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  if (unauthorized) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <React.Fragment>
        <Navbar>
          {type === "user" && (
            <React.Fragment>
              <h5 className="mr-auto">My Applications</h5>
              <Button
                variant="primary"
                size="sm"
                className="mb-2"
                onClick={createApplication}
              >
                Create New Application
              </Button>
            </React.Fragment>
          )}
          {type === "all" && (
            <React.Fragment>
              <h5 className="mr-auto">
                Submitted Applications{" "}
                <select
                  className="form-control-sm"
                  onChange={handleSelectChange}
                >
                  <option value="-1">All Application Types</option>
                  {typesList}
                </select>
                {isAdmin && (
                  <Button
                    variant="primary"
                    size="sm"
                    className="ml-2"
                    onClick={onGrantTypeEdit}
                  >
                    Manage Grant Types
                  </Button>
                )}
              </h5>

              <Button
                variant="primary"
                size="sm"
                className="mb-2"
                onClick={exportApplication}
              >
                Export Applications
              </Button>
            </React.Fragment>
          )}
        </Navbar>

        <BootstrapTable
          keyField="applicationId"
          bootstrap4={true}
          data={applications}
          columns={applicationTableCols}
          striped={true}
          bordered={true}
          hover={true}
          condensed={true}
          defaultSorted={[
            { dataField: "status", order: "asc" },
            { dataField: "submitDate", order: "desc" },
          ]}
          selectRow={selectRowProp}
          wrapperClasses="applicationTable"
          noDataIndication="No applications submitted"
        />

        <EditGrantTypeModal
          show={showEditGrantTypeModal}
          onClose={() => setShowEditGrantTypeModal(false)}
          grantTypes={appTypes}
          onSave={onSaveGrantTypes}
        />

        {selectedApplication && (
          <ApplicationDetails
            selectedApplication={selectedApplication}
            downloadZip={downloadZip}
            type={type}
            isAdmin={isAdmin}
            hideFileStatus={!isAdmin}
            handleStatusChange={handleStatusChange}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UserApplications;
