import React, { useState, useEffect, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import authService from "./api-authorization/AuthorizeService";
import Config from "../config.json";
import axios from "axios";
import { useHistory } from "react-router-dom";
import AuthErrorHelper from "./shared/AuthErrorHelper";
import UserDetails from "./UserDetails";
import { PencilIcon } from "@primer/octicons-react";
import EditUserModal from "./modals/EditUserModal";
import { DomainContext } from "../Context";

function UserList() {
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [userId, setUserId] = useState();
  const [showEditUser, setShowEditUser] = useState(false);
  const [userRole, setUserRole] = useState();
  const [isReviewer, setIsReviewer] = useState();
  const [userGrantTypes, setUserGrantTypes] = useState([]);
  const { userRoles } = useContext(DomainContext);
  const { appTypes} = useContext(DomainContext);

  const UserTableCols = [
    { dataField: "user.id", text: "UserId", hidden: true },
    {
      dataField: "user.email",
      text: "Email",
      headerStyle: () => {
        return { width: "40%" };
      },
      sort: true,
    },
    { dataField: "role_str", text: "Role", sort: true },
  ];

  useEffect(() => {
    getUsers();
  }, [userRole, userGrantTypes, isReviewer]);

  const getUsers = async () => {
    try {
      const token = await authService.getAccessToken();
      const { data: result } = await axios.get(
        Config.apiUrl + "api/UserManage/AllUsers",
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      console.log(result);
      console.log(selectedUser);
      setUsers(result);
      //setUserGrantTypes(result.userGrantTypes)
    } catch (error) {
      if (error.response.status === 401) {
        redirectLogin();
      }
    }
  };

  const redirectLogin = () => {
    let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
    history.push(redirectUrl);
  };
  /*
  const editUser = (user) => {
    //edit user role
    setShowEditUser(ture);
  };
*/
  const onSaveUserRoles = (user) => {
    //call api
    setShowEditUser(false);
    getUsers();
    setSelectedUser(user);
  };

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "LightBlue",
    headerColumnStyle: { width: "76px" },
    selected: selectedRow,

    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedUser(row);
      setSelectedRow([row.user.id]);
      setUserRole(row.user.roles);
      setIsReviewer(row.isReviewer);
      setUserGrantTypes(row.userGrantTypes)
      setUserId(row.user.id)
    },

    //Selection row
    selectionRenderer: ({ mode, checked, disabled }) => {
      return (
        <div className="container p-0 m-0 d-flex justify-content-center">
          <div className="btn-group">
            {checked === true && (
              <React.Fragment>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => setShowEditUser(true)}
                >
                  <PencilIcon />
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    },
  };

  const exportUsers = async (event) => {
    try {
      const token = await authService.getAccessToken();
      const { data: response } = await axios.get(
        Config.apiUrl + "api/UserManage/ExportAsExcel",
        {
          responseType: "blob",
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      let date = new Date();

      let dateToday =
        date.getFullYear() + "_" + (date.getMonth() + 1) + "_" + date.getDate();

      let file_name = "Export_Users_" + dateToday;

      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error.response.status === 401) {
        let redirectUrl = AuthErrorHelper.reLogin(window.location.href);
        history.push(redirectUrl);
      }
    }
  };

  return (
    <React.Fragment>
      <Navbar>
        <h5 className="mr-auto">Registered Users</h5>

        <Button
          variant="primary"
          size="sm"
          className="mb-2"
          onClick={exportUsers}
        >
          Export Users
        </Button>
      </Navbar>

      <BootstrapTable
        keyField="user.id"
        bootstrap4={true}
        data={users}
        columns={UserTableCols}
        striped={true}
        bordered={true}
        hover={true}
        condensed={true}
        defaultSorted={[{ dataField: "user.email", order: "asc" }]}
        selectRow={selectRowProp}
        wrapperClasses="applicationTable"
        noDataIndication="No Users"
      />

      {selectedUser && <UserDetails user={selectedUser} />}
      <EditUserModal
        show={showEditUser}
        onClose={() => setShowEditUser(false)}
        onSave={onSaveUserRoles}
        user={selectedUser}
        roles={userRoles}
        grantTypes={appTypes}
        isReviewer={isReviewer}
        userGrantTypes={userGrantTypes}
        userId={userId}
      />
    </React.Fragment>
  );
}

export default UserList;
