import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function ConfirmModal({
  show,
  title,
  type,
  //confirmButton,
  //enableTextInput,
  onClose,
  onConfirm,
}) {
  const confirmModal = (e) => {
    onConfirm(type);
  };

  const closeModal = (e) => {
    onClose(e);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <h6 className="m-0">{title}</h6>
      </Modal.Header>
      <Modal.Body>
        {type === "Save" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>
                  Your application will be saved and can be edited from the "My
                  Applications" page before submitting.
                </Form.Label>
                <Form.Label>
                  <b>Your application has not yet been submitted.</b> To submit,
                  please click the "Submit" button on "My Applications" page.
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
        {type === "AdminSave" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>
                  Please confirm the changes to this submitted application.
                </Form.Label>
                <Form.Label>
                  Are you sure you want to save these changes to this
                  application?
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
        {type === "Submit" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>
                  Please confirm submission of your application.{" "}
                  <b>You will not be able to edit after submitting.</b>
                </Form.Label>
                <Form.Label>
                  Are you sure you want to submit this application for review?
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
        {type === "Edit" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>The application has been submitted.</Form.Label>
                <Form.Label>
                  Are you sure you want to edit this application?
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
        {type === "Cancel" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>
                  The application information changes will be discarded after
                  canceling.
                </Form.Label>
                <Form.Label>
                  Are you sure you want to cancel this application?
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
        {type === "Delete" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>
                  The attachment will be deleted from the application.
                </Form.Label>
                <Form.Label>
                  Are you sure you want to delete this attachment?
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
        {type === "DeleteApp" && (
          <div className="modalWindow">
            <Form>
              <Form.Group>
                <Form.Label>
                  The application will be removed from system.
                </Form.Label>
                <Form.Label>
                  Are you sure you want to delete this application?
                </Form.Label>
              </Form.Group>
            </Form>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary"
          size="sm"
          onClick={confirmModal}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
