import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import bsCustomFileInput from "bs-custom-file-input";
import axios from "axios";
import Config from "../../config.json";
import authService from "../api-authorization/AuthorizeService";
import PopoverAlert from "../shared/PopoverAlert";
import AuthErrorHelper from "./../shared/AuthErrorHelper";

function UploadAttachmentModal({ show, appId, onClose, onUpload }) {
  const [attachment, setAttachment] = useState(null);
  const history = useHistory();

  const closedErrorMessage = {
    show: false,
    title: "",
    content: "",
  };
  const [errorMessage, setErrorMessage] = useState(closedErrorMessage);
  const uploadBtn = useRef(null);

  // Initialize file input
  useEffect(() => {
    bsCustomFileInput.init();
  });

  // Set attachment file
  const onChangeAttachment = (e) => {
    setAttachment(e.target.files);
  };
  /*
  const handleTextChange = (event) => {
    setDescription(event.target.value);
  };*/
  // Upload attachment workflow
  const handleUploadAttachment = async (e) => {
    e.preventDefault();
    if (!attachment) {
      setErrorMessage({
        show: true,
        title: "Select Attachment",
        content: "Please select a valid attachment for upload.",
      });
      return;
    } else {
      setErrorMessage(closedErrorMessage);
    }
    // Upload file
    uploadFile();
  };

  //Uploads file to server
  async function uploadFile() {
    //let appId = prevLocation.state.id;
    let uploadedFiles = [];
    let errors = [];

    for (let i = 0; i < attachment.length; i++) {
      const token = await authService.getAccessToken();
      const formData = new FormData();
      // Update the formData object
      formData.append("file", attachment[i], attachment[i].name);
      formData.append("ApplicationId", appId);
      try {
        const { data: file } = await axios.post(
          Config.apiUrl + "api/ApplicationFiles",
          formData,
          {
            onUploadProgress: (progressEvent) => {
              console.log(
                "progress:  " +
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            },

            headers: !token
              ? {}
              : {
                  Authorization: `Bearer ${token}`,
                  "content-type": "multipart/form-data",
                },
          }
        );
        uploadedFiles.push(file);
      } catch (error) {
        if (error.response.status === 401) {
          let redirectUrl = AuthErrorHelper.returnHome();
          history.push(redirectUrl);
          return;
        } else {
          console.log("Post file error");
          errors.push(attachment[i].name + " " + error.response.data);
        }
      }
    }
    //closeModal();
    if (errors.length > 0) {
      setErrorMessage({
        show: true,
        title: "Service Error",
        content: errors.toString(),
      });
    } else {
      closeModal();
    }
    onUpload(uploadedFiles);
  }

  const closeModal = () => {
    setAttachment(null); // Clear attachment
    setErrorMessage(closedErrorMessage); // Clear error message
    onClose();
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header>
        <h6 className="m-0"> Upload Attachment</h6>
      </Modal.Header>
      <Modal.Body>
        <div className="modalWindow">
          <Form>
            <Form.Group>
              <Form.Label>
                Click 'Browse' to navigate to the location of the document to be
                uploaded to this request.
              </Form.Label>

              <div className="custom-file">
                <input
                  id="inputAttachment"
                  type="file"
                  accept=".pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png"
                  multiple
                  className="custom-file-input"
                  onChange={onChangeAttachment}
                />
                <label className="custom-file-label">
                  <span className="d-inline-block text-truncate w-75"></span>
                </label>
              </div>
            </Form.Group>

            {/*<Form.Group>
              <Form.Label>File Description</Form.Label>

              <input
                type="text"
                className="form-control form-control-sm"
                id="descriptopn"
                value={description}
                onChange={handleTextChange}
              />
            </Form.Group>*/}
          </Form>
          <Nav className="justify-content-center">
            <Button
              variant="primary"
              size="sm"
              onClick={handleUploadAttachment}
              ref={uploadBtn}
            >
              Upload Attachment
            </Button>
          </Nav>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>

      <PopoverAlert
        id="errorMessage"
        show={errorMessage.show}
        target={uploadBtn}
        title={errorMessage.title}
        content={errorMessage.content}
      />
    </Modal>
  );
}

export default UploadAttachmentModal;
